import { useBoundaryStore } from 'src/store';
import { memo, MutableRefObject, useEffect, useRef } from 'react';
import { nFormatter } from 'src/utils/formatters';

type Props = {
    refBalance: MutableRefObject<HTMLDivElement | null>;
};
export const RefConnector = memo(({ refBalance }: Props) => {
    const balance = useBoundaryStore((s) => s.balance);
    const countClick = useBoundaryStore((s) => s.countClick);

    useEffect(() => {
        if (refBalance.current) {
            refBalance.current.innerHTML = nFormatter({ num: balance, precision: 0 });
        }
    }, [balance]);

    return <></>;
});
