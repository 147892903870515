import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DOMAIN, PagesEnum } from 'src/constants';
import { Init } from 'src/Init';
import { StartWrapper } from 'src/components/start-wrapper';

export const StartContainer: FC = () => {
    const isShowIntro = !localStorage.getItem('intro');
    const navigate = useNavigate();

    const whereToGo = () => {
        setTimeout(() => {
            if (isShowIntro) {
                navigate(`${DOMAIN}${PagesEnum.Intro}`);
            } else {
                navigate(`${DOMAIN}${PagesEnum.Main}`);
            }
        }, 2000);
    };

    useEffect(() => {
        whereToGo();
    }, [isShowIntro]);

    return (
        <>
            <Init />
            <StartWrapper />
        </>
    );
};
