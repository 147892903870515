import { StateCreator } from 'zustand';
import {
    buyObjectApi,
    getObjectsApi,
    initialLevelObjects,
    initialObjectsByType,
    LevelObjects,
    ObjectItem,
    ObjectItemStatus,
    ObjectItemType,
} from 'src/api/objects';
import { UserSlice } from 'src/store/user';
import { IncomeSlice } from 'src/store/income';

interface Actions {
    getObjects: () => void;
    handleBuyObject: (id: string) => void;
    enableIsLoadingBuy: () => void;
}

interface State {
    objects?: ObjectItem[];
    levelObjects: LevelObjects;
    objectsByType?: Record<ObjectItemType, ObjectItem[]>;
    isLoadingBuy: boolean;
}

export type ObjectsSlice = State & Actions;

export const objectsSlice: StateCreator<ObjectsSlice & UserSlice & IncomeSlice, [], [], ObjectsSlice> = (
    set,
    get
) => ({
    objects: [],
    levelObjects: initialLevelObjects,
    isLoadingBuy: false,
    getObjects: async () => {
        const { data: objects } = await getObjectsApi();
        const levelObjects: LevelObjects = { ...initialLevelObjects },
            objectsByType: Record<ObjectItemType, ObjectItem[]> = JSON.parse(JSON.stringify(initialObjectsByType));
        objects.forEach((object) => {
            if (!objectsByType[object.type]) {
                objectsByType[object.type] = [];
            }
            objectsByType[object.type].push(object);
            if (object.status === ObjectItemStatus.ownedStatus) {
                const level = objectsByType[object.type].findLastIndex(
                    (v) => v.status === ObjectItemStatus.ownedStatus
                );
                levelObjects[object.type] = level + 1;
            }
        });
        set({ objects, objectsByType, levelObjects });
    },
    handleBuyObject: (id) => {
        buyObjectApi(id)
            .then(() => {
                const stats = { ...get().stats };
                stats.objectIncome = stats.objectIncome?.map((v) =>
                    v.id === id ? { ...v, objectLevel: v.objectLevel + 1 } : v
                );
                // @ts-ignore
                set({ stats });
                get().getObjects();
                get().getIncomeStats();
                get().getUserInfo(true);
            })
            .finally(() => {
                set({ isLoadingBuy: false });
            });
    },
    enableIsLoadingBuy: () => {
        set({ isLoadingBuy: true });
    },
});
