import classNames from 'classnames';
import styles from './index.module.css';
import ArrowRightPic from 'src/assets/svg/arrow-right-circle.svg';
import CheckPic from 'src/assets/svg/check-green.svg';
import React, { FC } from 'react';

type Props = {
    img: string;
    title: string;
    subtitle: string;
    onSelectTask?: () => void;
    isCompleted?: boolean;
};
export const BonusItemBlock: FC<Props> = ({ onSelectTask, title, subtitle, img, isCompleted = false }) => (
    <div className={classNames(styles.bonusBlock, isCompleted && styles.bonusBlock_completed)} onClick={onSelectTask}>
        <img src={img} alt="" />
        <div className={styles.bonusBlockContent}>
            <div>{title}</div>
            <div>{subtitle}</div>
        </div>
        <div>
            <img src={isCompleted ? CheckPic: ArrowRightPic} alt="" />
        </div>
    </div>
);
