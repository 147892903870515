import { StateCreator } from 'zustand';
import {getIncomeStatsApi, InviteEnum, UserIncomeStats} from 'src/api/user';

interface Actions {
    getIncomeStats: () => void;
}

interface State {
    stats?: UserIncomeStats;
}

export type IncomeSlice = State & Actions;

export const incomeSlice: StateCreator<IncomeSlice> = (set, get) => ({
    getIncomeStats: async () => {
        const { data: stats } = await getIncomeStatsApi();
        const objActiveIncome = stats.activeIncome.incomeFromActive.reduce((acc, item) => {
            if (acc[item.type]) {
                return { ...acc, [item.type]: { ...item, income: acc[item.type].income + item.income } }
            }
            return { ...acc, [item.type]: item }
        }, {} as Record<InviteEnum, UserIncomeStats["activeIncome"]["incomeFromActive"][number]>)
        stats.activeIncome.incomeFromActive = Object.values(objActiveIncome) || [];
        set({ stats });
    },
});
