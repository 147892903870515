import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import debounce from 'debounce';
import { MinerBlock } from 'src/components/miner-block';
import { FREQUENT_CPS_AMOUNT } from 'src/constants';
import { cpsFunc } from 'src/utils/cps';
import { useBoundaryStore } from 'src/store';
import styles from './index.module.css';
import {PointsWrapper2} from "src/components/points-wrapper/index2";

type Props = {
    platform: React.ReactNode;
    cpsUtil: ReturnType<typeof cpsFunc>;
    finishInitLoading: () => void;
};

export const MainContainer: FC<Props> = memo(({ platform, cpsUtil, finishInitLoading }) => {
    const cps = cpsUtil.getCps();
    const [cps1, setCps1] = useState(0);
    const emitterAddPoints = useRef<(x: number, y: number) => void>(() => {});
    const isDisabledMiner = useBoundaryStore((s) => s.isDisabledMiner);
    const isTurbo = useBoundaryStore((s) => s.isTurbo);
    const minerLevel = useBoundaryStore((s) => s.miner.level);

    const getUserInfoData = useBoundaryStore((s) => s.getUserInfo);
    const handleStopFrequent = useBoundaryStore((s) => s.handleStopFrequent);
    const handleTapMiner = useBoundaryStore((s) => s.handleTapMiner);

    const checkCps = debounce(() => {
        setCps1(cpsUtil.getCps())
    }, 1000);

    const minerTouchEnd = useCallback((cps: number, cb: () => void) => {
        if (!isDisabledMiner) {
            cpsUtil.mouseUp();
            setCps1(cpsUtil.getCps())
            checkCps()
            handleTapMiner(cps, cb);
        }
    }, [isDisabledMiner]);
    const touchEnd = useCallback((x: number, y: number) => {
        minerTouchEnd(cps, () => emitterAddPoints.current(x, y));
    }, [cps, isDisabledMiner]);

    useEffect(() => {
        if (cps1 < FREQUENT_CPS_AMOUNT) {
            handleStopFrequent();
        }
    }, [cps1]);
    useEffect(() => {
        getUserInfoData();
        finishInitLoading();
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContainerBg}>
                <PointsWrapper2 emitterAddPoints={emitterAddPoints} />
                {platform}
                <MinerBlock
                    level={minerLevel}
                    touchStart={cpsUtil.mouseDown}
                    touchEnd={touchEnd}
                    disabled={isDisabledMiner}
                    isTurbo={isTurbo}
                />
                <div style={{marginBottom: '15px'}}></div>
            </div>
        </div>
    );
});
