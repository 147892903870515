const getTimeNow = () => new Date().getTime();

export const cpsFunc = () => {
    let clicks = 0,
        startTime = 0,
        endTime = 0,
        activeTime = 0,
        cps = 0,
        instantCps = 0,
        cpsMax = 0;

    return ({
        getCps() {
            return instantCps;
        },

        mouseDown() {
            activeTime = getTimeNow() - startTime;
            clicks++;

            if ((getTimeNow() - endTime) > 1000) {
                clicks = cps = instantCps = cpsMax = 0;
                startTime = getTimeNow();
            }

            if (activeTime > 100) {
                instantCps = 1000 / (getTimeNow() - endTime);
                cps = (clicks / activeTime) * 1000;
            }

            if (activeTime > 1000) cpsMax = Math.max(cps, cpsMax);
        },

        mouseUp() {
            endTime = getTimeNow();
            setTimeout(() => {
                if ((getTimeNow() - endTime) > 1000) {
                    clicks = cps = instantCps = cpsMax = 0;
                    startTime = getTimeNow();
                }
            }, 1000)
        }
    });
};
