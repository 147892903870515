import api from 'src/api/index';

export enum ObjectItemStatus {
    'ownedStatus' = 'OWNED',
    'availableStatus' = 'AVAILABLE',
    'notAvailableStatus' = 'NOT_AVAILABLE',
}
export enum ObjectItemType {
    'car' = 'CAR',
    'hotel' = 'HOTEL',
    'object' = 'OBJECT',
}

export type ObjectItem = {
    id: string;
    type: ObjectItemType;
    name: string;
    price: number;
    dailyIncome: number;
    status: ObjectItemStatus;
};

export type LevelObjects = Record<ObjectItemType, number>;
export const initialLevelObjects: LevelObjects = {
    [ObjectItemType.object]: 0,
    [ObjectItemType.hotel]: 0,
    [ObjectItemType.car]: 0,
};
export const initialObjectsByType: Record<ObjectItemType, ObjectItem[]> = {
    [ObjectItemType.car]: [],
    [ObjectItemType.hotel]: [],
    [ObjectItemType.object]: [],
};

export const getObjectsApi = () => api.get<ObjectItem[]>('object/info');

export const buyObjectApi = (objectId: string) => api.post<ObjectItem[]>('object', { objectId });
