import miner1 from 'src/assets/images/miners/miner_1_level.png';
import miner2 from 'src/assets/images/miners/miner_2_level.png';
import miner3 from 'src/assets/images/miners/miner_3_level.png';
import miner4 from 'src/assets/images/miners/miner_4_level.png';
import miner5 from 'src/assets/images/miners/miner_5_level.png';
import miner6 from 'src/assets/images/miners/miner_6_level.png';
import miner7 from 'src/assets/images/miners/miner_7_level.png';
import miner8 from 'src/assets/images/miners/miner_8_level.png';
import miner9 from 'src/assets/images/miners/miner_9_level.png';
import miner10 from 'src/assets/images/miners/miner_10_level.png';

export const getMinerByLevel = (level: number) => {
    switch (level) {
        case 0: case 1: return miner1;
        case 2: return miner2;
        case 3: return miner3;
        case 4: return miner4;
        case 5: return miner5;
        case 6: return miner6;
        case 7: return miner7;
        case 8: return miner8;
        case 9: return miner9;
        case 10: return miner10;
    }
}