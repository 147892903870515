export const startTimer = (duration: number, setRemainingTime: (v: string) => void): NodeJS.Timer => {
    let timer = parseInt((duration / 1000000000).toFixed(0), 10), // из наносек в сек
        hours,
        minutes,
        seconds;
    return setInterval(function () {
        hours = Math.floor(timer / (60*60));
        minutes = Math.floor((timer - (hours * 3600)) / 60);
        seconds = timer - (hours * 3600) - (minutes * 60);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        if (--timer < 0) {
            timer = duration;
        }
        setRemainingTime(`${hours}:${minutes}:${seconds}`)
    }, 1000);
};
