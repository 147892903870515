import React, {FC, useEffect, useRef, useState} from 'react';
import CoinPic from '../../assets/svg/coin-header.svg';
import MinerMiniPic from '../../assets/images/miner-mini.png';
import RocketPic from '../../assets/images/rocket.png';
import EnergyPic from '../../assets/images/energy.png';
import { BlockWrapper } from 'src/components/block-wrapper';
import { Button } from 'src/components/button';
import { MinerBlock } from 'src/components/miner-block';
import { AssetItemWrapper } from 'src/components/asset-item-wrapper';
import styles from './index.module.css';
import {DOMAIN, PagesEnum} from 'src/constants';
import { useNavigate } from 'react-router-dom';
import {numberWithSeparationThousands} from "src/utils/formatters";
import {startTimer} from "src/pages/boost/helper";
import {useBoundaryStore} from "src/store";
export const BoostContainer: FC = () => {
    const minersInfo = useBoundaryStore((s) => s.miner);
    const balance = useBoundaryStore((s) => s.balance);
    const turboBoost = useBoundaryStore((s) => s.turboBoost);
    const boost = useBoundaryStore((s) => s.boost);
    const minerId = useBoundaryStore((s) => s.minerId);
    const boostRefreshDate = useBoundaryStore((s) => s.boostRefreshDate);
    const pointsPerClick = useBoundaryStore((s) => s.pointsPerClick);
    const [remainingTime, setRemainingTime] = useState('');
    const navigate = useNavigate();
    const timer = useRef<NodeJS.Timer>();

    const getMinersData = useBoundaryStore((s) => s.getMinersInfo);
    const getUserInfoData = useBoundaryStore((s) => s.getUserInfo);
    const handleBuyMiner = useBoundaryStore((s) => s.handleBuyMiner);
    const handleRecharge = useBoundaryStore((s) => s.handleRecharge);
    const handleActivateTurboView = useBoundaryStore((s) => s.handleActivateTurboView);

    const isDisabledButtonBuy = !minersInfo?.price || balance < minersInfo?.price;

    const buyMiner = () => {
        if (isDisabledButtonBuy) return;

        handleBuyMiner(minersInfo.newId);
    };

    const activateRecharge = () => {
        if (boost) {
            navigate(`${DOMAIN}${PagesEnum.Main}`);
            handleRecharge();
        }
    };

    const activateTurbo = () => {
        if (turboBoost) {
            handleActivateTurboView();
            navigate(`${DOMAIN}${PagesEnum.Main}`);
        }
    };

    useEffect(() => {
        getMinersData(minerId);
        getUserInfoData();
    }, []);

    useEffect(() => {
        if (!boost || !turboBoost) {
            if (timer.current) {
                clearInterval(timer.current);
            }
            timer.current = startTimer(boostRefreshDate, setRemainingTime);
        } else {
            clearInterval(timer.current);
        }
    }, [boostRefreshDate, boost, turboBoost]);

    return (
        <div className={styles.boostWrapper}>
            <div className={styles.row}>
                <AssetItemWrapper
                    img={EnergyPic}
                    middle={{ text: `${boost} / ${minersInfo.boost}` }}
                    title={'Энергия'}
                    button={
                        <Button onClick={activateRecharge} size={'s'} disabled={!boost}>
                            {boost ? 'Получить' : (remainingTime || 'Получить')}
                        </Button>
                    }
                    status={boost ? 'default' : 'ghost'}
                />
                <AssetItemWrapper
                    img={RocketPic}
                    middle={{ text: `${turboBoost} / ${minersInfo.turboBoost}` }}
                    title={'Турбобуст'}
                    button={
                        <Button onClick={activateTurbo} size={'s'} disabled={!turboBoost}>
                            {turboBoost ? 'Получить' : (remainingTime || 'Получить')}
                        </Button>
                    }
                    status={turboBoost ? 'default' : 'ghost'}
                />
            </div>
            <BlockWrapper className={styles.blockWrapper}>
                <div className={styles.minerTop}>
                    <div className={styles.minerTopLeft}>
                        <img src={MinerMiniPic} alt="" />
                        <div>Майнер ур. {minersInfo.level}</div>
                    </div>
                    <div className={styles.minerTopRight}>
                        <div>
                            Очков: <span>{pointsPerClick} / тап</span>
                        </div>
                        <img src={CoinPic} alt="" />
                    </div>
                </div>
                <div className={styles.miner}>
                    <MinerBlock level={minersInfo.level} viewMode />
                </div>
                <Button icon={CoinPic} onClick={buyMiner} disabled={isDisabledButtonBuy}>
                    Новый уровень за {numberWithSeparationThousands(minersInfo.price)}
                </Button>
            </BlockWrapper>
        </div>
    );
};
