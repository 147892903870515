import React from 'react';
import { BlockWrapper } from 'src/components/block-wrapper';
import { numberWithSeparationThousands } from 'src/utils/formatters';
import CoinPic from 'src/assets/svg/coin-header.svg';
import styles from './index.module.css';

export const LeaderBlock = ({
    points,
    title,
    pic,
    content,
    className,
}: {
    pic: string;
    title: string;
    className?: string;
    points?: number;
    content: React.ReactElement;
}) => (
    <BlockWrapper className={className}>
        <div className={styles.leadersBlockHeader}>
            <div className={styles.incomeCityTitle}>
                <div>
                    <img src={pic} alt=":)" />
                </div>
                <div>{title}</div>
            </div>
            {points ? (
                <div>
                    <div>+{numberWithSeparationThousands(points)}</div>
                    <img src={CoinPic} alt="O" />
                </div>
            ) : null}
        </div>
        {content}
    </BlockWrapper>
);
