import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.css';

type Props = {
    emitterAddPoints: React.MutableRefObject<(x: number, y: number) => void>;
};
const pointsLength = 20;
const arrList = Array.apply(null, Array(pointsLength)) as Array<any>;
const pointsList = arrList.map(() => Math.random());
export const PointsWrapper2: FC<Props> = memo(({ emitterAddPoints }) => {
    let last = -1;
    const elemsRef = useRef<HTMLDivElement[]>(arrList);

    emitterAddPoints.current = (x, y) => {
        last = last === pointsLength - 1 ? 0 : last + 1;
        elemsRef.current[last].classList.add(styles[`anim`]);
        elemsRef.current[last].style.setProperty('--offX', `${x}px`);
        elemsRef.current[last].style.setProperty('--offY', `${y}px`);
        if (last < pointsLength - 1) {
            elemsRef.current[last + 1].className = styles.coinWrapper;
        } else {
            elemsRef.current[0].className = styles.coinWrapper;
        }
    };

    return (
        <>
            {pointsList.map((v, i) => (
                <div
                    key={`${v}`}
                    ref={(el) => (elemsRef.current[i] = el as HTMLDivElement)}
                    className={classNames(styles.coinWrapper)}
                />
            ))}
        </>
    );
});
