import React, {FC, memo, MutableRefObject} from 'react';
import classNames from 'classnames';
import Coin from '../../assets/svg/coin-header.svg';
import { nFormatter } from 'src/utils/formatters';
// import { AnimatedNumber } from 'src/components/animated-number';
import styles from './index.module.css';
import { useBoundaryStore } from 'src/store';

type Props = {
    isMainPage: boolean;
    refBalance: MutableRefObject<HTMLDivElement | null>;
};
export const Header: FC<Props> = memo(({ isMainPage, refBalance }) => {
    const avatar = useBoundaryStore((s) => s.avatar);
    const username = useBoundaryStore((s) => s.username);

    return (
        <div className={classNames(styles.header, !isMainPage && styles.headerMinimal)}>
            <div className={styles.headerItem}>
                <div className={styles.headerItemAvatar} style={{ backgroundImage: `url(${avatar || ''})` }}>
                    {avatar ? null : username[0]?.toUpperCase() || ''}
                </div>
                <div>{username}</div>
            </div>
            <div className={styles.headerItem}>
                <div ref={refBalance}></div>
                <div className={styles.headerItemPic}>
                    <img src={Coin} alt="O" />
                </div>
            </div>
        </div>
    );
});
