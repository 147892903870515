import { StateCreator } from 'zustand';
import { getLeadersApi, LeadersItem } from 'src/api/user';

import { getProfilePhoto } from 'src/api/photo';

interface Actions {
    getLeaders: () => void;
    addLeadersAvatar: () => void;
    setIsLoadingLeaders: () => void;
}

interface State {
    leaderIds: string[];
    leadersById: Record<string, LeadersItem>;
    photoById: Record<string, string>;
    isLoadedAvatars: boolean;
    isLoadingLeaders: boolean;
}
export type LeadersSlice = State & Actions;
export const leadersSlice: StateCreator<LeadersSlice> = (set, get) => ({
    leaderIds: [],
    leadersById: {},
    photoById: {},
    isLoadedAvatars: false,
    isLoadingLeaders: false,
    getLeaders: async () => {
        const leaderIds: string[] = [],
            leadersById = { ...(get().leadersById || {}) };
        const {
            data: { leaders },
        } = await getLeadersApi();
        leaders.forEach((item) => {
            leaderIds.push(item.id);
            if (leadersById[item.id]) {
                const { photo, ...rest } = leadersById[item.id];
                leadersById[item.id] = { ...item, photo };
            } else {
                leadersById[item.id] = item;
            }
        });
        set({ leaderIds, leadersById, isLoadingLeaders: false });
        get().addLeadersAvatar();
    },
    addLeadersAvatar: async () => {
        const leaderIds = [...(get().leaderIds || [])],
            photoById = { ...(get().photoById || {}) };
        Promise.allSettled(leaderIds.map((id) => getProfilePhoto(get().leadersById[id]?.telegramId)) || []).then(
            (results) => {
                results.forEach((result, index) => {
                    photoById[leaderIds[index]] = result.status === 'fulfilled' ? result.value : '';
                });
            }
        );
        set({ photoById, isLoadedAvatars: true });
    },
    setIsLoadingLeaders: () => {
        set({ isLoadingLeaders: true });
    },
});
