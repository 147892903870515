import React, { FC, memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import MinerWrapperPic from '../../assets/images/miner-wrapper.png';
import MinerBg from '../../assets/images/miner-blur-bg.png';
import { getMinerByLevel } from 'src/components/miner-block/constants';
import styles from './index.module.css';

type Props = {
    touchStart?: () => void;
    touchEnd?: (x: number, y: number) => void;
    level: number;
    disabled?: boolean;
    ref?: React.Ref<any>;
    viewMode?: boolean;
    isTurbo?: boolean;
};

export const MinerBlock: FC<Props> = memo(
    ({ disabled = false, touchStart, touchEnd, viewMode = false, isTurbo = false, level }) => {
        const ref = useRef<HTMLImageElement | null>(null);
        const onDown = () => {
            if (!viewMode) {
                touchStart?.();
                ref.current!.style.transform = 'translateX(-50%) scale(1.05)';
            }
        };
        // @ts-ignore
        const onUp = (e) => {
            const rect = e.target.getBoundingClientRect();
            if (!viewMode) {
                touchEnd?.(e.changedTouches[0].clientX - rect.left, e.changedTouches[0].clientY - rect.top - 45);
                ref.current!.style.transform = 'translateX(-50%) scale(1)';
            }
        };

        return (
            <div className={classNames(styles.minerWrapper, disabled && styles.disabled)} ref={ref}>
                {viewMode ? null : <img className={styles.minerWrapperBGg} src={MinerBg} alt="" />}
                <div className={classNames(styles.turboEffect, isTurbo && styles.turboActive)}></div>
                <div className={classNames(styles.turboEffect, styles.turboEffect2, isTurbo && styles.turboActive)}></div>
                <img className={styles.minerWrapperPic} src={MinerWrapperPic} alt="" />
                <div className={classNames(styles.turboEffect, styles.turboEffect3, isTurbo && styles.turboActive)}></div>
                <div className={styles.miner}>
                    <img
                        className={styles.minerPic}
                        src={getMinerByLevel(level)}
                        alt=""
                        onTouchStart={onDown}
                        onTouchEnd={onUp}
                    />
                </div>
            </div>
        );
    }
);
