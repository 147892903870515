import React, {FC, useState} from "react";
import CheckSvg from "src/assets/svg/check.svg";
import CopyPic from "src/assets/svg/copy.svg";
import {Button} from "src/components/button";
import PlusCirclePic from "src/assets/images/plus-circle.png";
import styles from "./index.module.css";

type Props = {
    userId: string
}
export const InviteContent: FC<Props> = ({ userId }) => {
    const [isCopied, setIsCopied] = useState(false);
    const inviteLink = `${process.env.REACT_APP_INVITE_LINK}?startapp=${userId}`;
    return (
        <div>
            <div className={styles.leadersInput}>
                <div>{inviteLink}</div>
                <img
                    src={isCopied ? CheckSvg : CopyPic}
                    alt=""
                    onClick={() => {
                        navigator.clipboard.writeText(inviteLink);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 2000);
                    }}
                />
            </div>
            <Button
                icon={PlusCirclePic}
                href={`https://t.me/share/url?url=${inviteLink}&text=Заходи в игру и зарабатывай!💸🔥`}
            >
                Пригласить друга
            </Button>
        </div>
    )
}