import React, { PureComponent } from 'react';

type Props = {
    callback: (...args: any[]) => void;
    delay: number;
    skipFirst?: boolean;
    children: React.ReactNode;
};

export default class Interval extends PureComponent<Props> {
    static defaultProps = {
        children: null,
    };

    continues?: boolean;
    timerID?: NodeJS.Timeout;

    componentDidMount() {
        this.continues = true;
        if (this.props.skipFirst) {
            setTimeout(() => this.execute(), this.props.delay);
        } else {
            this.execute();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timerID);
        this.continues = false;
    }

    requestFrame = (x: any) => (requestAnimationFrame ? requestAnimationFrame(x) : x());

    execute() {
        this.requestFrame(() => {
            if (!this.continues) {
                return;
            }

            return Promise.resolve(this.props.callback()).finally(() => {
                this.timerID = setTimeout(() => this.execute(), this.props.delay);
            });
        });
    }

    render() {
        return this.props.children;
    }
}
