import {FC, memo, useEffect} from 'react';
const tg = window.Telegram?.WebApp;

export const Init: FC = memo(() => {
    useEffect(() => {
        tg?.expand();
        tg?.setHeaderColor('#0D1533');
    }, []);

    return <></>;
});
