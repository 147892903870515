import { create, StateCreator } from 'zustand';
import {
    completeTaskApi,
    getUserInfoApi,
    initialUserInfo,
    InviteEnum,
    updateUserInfoApi,
    UserData,
} from 'src/api/user';
import {
    DEFAULT_CLICKS_AMOUNT,
    FREQUENT_CPS_AMOUNT,
    FREQUENT_MULTIPLIER,
    TURBO_MULTIPLIER_CLICK,
    UPDATING_CLICKS_TIMOUT,
} from 'src/constants';
import { getProfilePhoto } from 'src/api/photo';
import { IncomeSlice } from 'src/store/income';
import { MinerSlice } from 'src/store/miner';

interface Actions {
    getUserInfo: (withCb?: boolean, isInit?: boolean, cb?: () => void) => void;
    updateUserInfo: () => void;
    updateUserClicks: () => void;
    handleRecharge: () => void;
    handleActivateTurbo: () => void;
    handleActivateTurboView: () => void;
    handleStopTurbo: () => void;
    handleStopFrequent: () => void;
    handleTapMiner: (cps: number, cb: () => void) => void;
    clicksCountUpdating: () => void;
    switchOnIsLoadingCompleteTask: () => void;
    getUserAvatar: () => void;
    completeTask: (taskId: InviteEnum, cb: () => void) => void;
}

interface State extends UserData {
    isTurbo: boolean;
    isTurboView: boolean;
    isFrequent: boolean;
    isDisabledMiner: boolean;
    isLoadingCompleteTask: boolean;
    avatar: string;
}

export type UserSlice = State & Actions;

export const userInfoSlice: StateCreator<UserSlice & IncomeSlice & MinerSlice, [], [], UserSlice> = (set, get) => ({
    ...initialUserInfo,
    isTurbo: false,
    isTurboView: false,
    isFrequent: false,
    isDisabledMiner: false,
    avatar: '',
    isLoadingCompleteTask: false,
    getUserInfo: async (withCb, isInit, cb) => {
        const { data } = await getUserInfoApi(
            isInit ? window.Telegram?.WebApp?.initDataUnsafe.start_param || '' : ''
        );
        if (withCb && data.minerId) {
            get().getMinersInfo(data.minerId);
        }
        cb?.();
        set(data);
    },
    updateUserInfo: () => {
        updateUserInfoApi({ countClick: get().countClick, countPoints: get().countPoints });
    },
    updateUserClicks: () => {
        updateUserInfoApi({ countClick: get().countClick });
    },
    handleTapMiner: (cps, cb) => {
        const { isTurbo, pointsPerClick, countClick, countPoints, balance } = get();
        const isFrequent = isTurbo ? false : cps > FREQUENT_CPS_AMOUNT;
        const multiplier = isTurbo ? TURBO_MULTIPLIER_CLICK : isFrequent ? FREQUENT_MULTIPLIER : 1;
        const ppc = pointsPerClick * multiplier;
        if (countClick - ppc >= 0) {
            set({
                countClick: countClick - ppc,
                countPoints: countPoints + ppc,
                balance: balance + ppc,
                isFrequent,
            });
            cb();
            window.Telegram?.WebApp?.HapticFeedback.impactOccurred('light');
        } else {
            set({ isDisabledMiner: true });
        }
    },
    handleRecharge: async () => {
        if (get().boost) {
            await updateUserInfoApi({ boost: get().boost - 1, countClick: DEFAULT_CLICKS_AMOUNT });
            set({
                countClick: DEFAULT_CLICKS_AMOUNT,
                isDisabledMiner: false,
            });
            get().getUserInfo(true);
        }
    },
    handleActivateTurbo: async () => {
        if (get().turboBoost) {
            await updateUserInfoApi({ turboBoost: get().turboBoost - 1, countClick: DEFAULT_CLICKS_AMOUNT });
            set({
                isTurbo: true,
                isTurboView: false,
                countClick: DEFAULT_CLICKS_AMOUNT,
                isDisabledMiner: false,
            });
            get().getUserInfo(true);
        }
    },
    clicksCountUpdating: () => {
        setTimeout(() => {
            const {pointsPerClick, isTurbo, countClick} = get();
            const multiplier = isTurbo ? TURBO_MULTIPLIER_CLICK : 1;
            const ppc = pointsPerClick * multiplier;
            if (countClick < DEFAULT_CLICKS_AMOUNT) {
                set({
                    countClick: countClick + 1,
                    ...(countClick - ppc >= 0 && { isDisabledMiner: false }),
                });
            }
            get().clicksCountUpdating();
            console.log('clicksCountUpdating')
        }, UPDATING_CLICKS_TIMOUT);
    },
    handleActivateTurboView: () => {
        set({ isTurboView: true });
    },
    handleStopTurbo: () => {
        set({ isTurbo: false });
    },
    handleStopFrequent: () => {
        set({ isFrequent: false });
    },
    switchOnIsLoadingCompleteTask: () => {
        set({ isLoadingCompleteTask: true });
    },
    getUserAvatar: async () => {
        const res = await getProfilePhoto(window.Telegram?.WebApp?.initDataUnsafe.user?.id);
        set({ avatar: res || '' });
    },
    completeTask: async (taskId, cb) => {
        await completeTaskApi(taskId, get().id);
        set({ isLoadingCompleteTask: false });
        get().getUserInfo();
        get().getIncomeStats();
        cb();
    },
});
