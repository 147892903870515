import React, {useEffect, useRef} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { MainContainer } from './pages/main-container';
import { Header } from './components/header';
import { IncomeContainer } from './pages/income';
import { DOMAIN, PagesEnum, UPDATING_USERINFO_TIMOUT } from './constants';
import { LeadersContainer } from './pages/leaders';
import { BoostContainer } from './pages/boost';
import { TownContainer } from './pages/town';
import { Navbar } from './components/navbar';
import { IntroContainer } from 'src/pages/intro';
import { StartContainer } from 'src/pages/start';
import Interval from 'src/components/Interval';
import { StartWrapper } from 'src/components/start-wrapper';
import { Footer } from 'src/pages/main-container/footer';
import { PlatformContainer } from 'src/components/platform/PlatformContainer';
import { cpsFunc } from 'src/utils/cps';
import { useBoundaryStore } from 'src/store';
import './App.css';
import {nFormatter} from "src/utils/formatters";
import {RefConnector} from "src/components/ref-connector";

const cpsUtil = cpsFunc();
function App() {
    const { pathname } = useLocation();
    const initApp = useBoundaryStore((s) => s.initApp);
    const isInitLoading = useBoundaryStore((s) => s.isInitLoading);
    const updateUserInfo = useBoundaryStore((s) => s.updateUserInfo);
    const finishInitLoadingStore = useBoundaryStore((s) => s.finishInitLoading);
    const refBalance = useRef<HTMLDivElement>(null);

    const finishInitLoading = () => {
        setTimeout(finishInitLoadingStore, 2000);
    };

    useEffect(() => {
        initApp();
    }, []);

    if (pathname === DOMAIN) {
        return <StartContainer />;
    }

    if (pathname === `${DOMAIN}${PagesEnum.Intro}`) {
        return <IntroContainer />;
    }

    return (
        <div className="App">
            <Interval callback={updateUserInfo} delay={UPDATING_USERINFO_TIMOUT} skipFirst />
            <RefConnector refBalance={refBalance} />
            {isInitLoading ? <StartWrapper /> : null}
            <Header refBalance={refBalance} isMainPage={pathname === `${DOMAIN}${PagesEnum.Main}`} />
            <Routes>
                <Route
                    path={`${DOMAIN}${PagesEnum.Main}`}
                    element={
                        <>
                            <MainContainer
                                cpsUtil={cpsUtil}
                                finishInitLoading={finishInitLoading}
                                platform={<PlatformContainer />}
                            />
                            <Footer />
                        </>
                    }
                />
                <Route path={`${DOMAIN}${PagesEnum.Income}`} element={<IncomeContainer />} />
                <Route path={`${DOMAIN}${PagesEnum.Leaders}`} element={<LeadersContainer />} />
                <Route path={`${DOMAIN}${PagesEnum.Boost}`} element={<BoostContainer />} />
                <Route path={`${DOMAIN}${PagesEnum.City}`} element={<TownContainer />} />
            </Routes>
            <Navbar />
        </div>
    );
}

export default App;
