// @ts-ignore
import AnimatedNumberComponent from 'animated-number-react';
import React, {FC} from "react";

type Props = {
    value: number;
    formatValue: (v: number) => string;
    duration?: number;
}
export const AnimatedNumber: FC<Props> = ({ value, formatValue, duration}) => (
    <AnimatedNumberComponent value={value} formatValue={formatValue} duration={duration} />
)