import React, { memo } from 'react';
import platform from 'src/assets/images/platform/platform.png';
import styles from './platform.module.css';
import { EPlatformItems, getIconPlatformByLevel } from 'src/components/platform/utils';
import classNames from 'classnames';
import { useBoundaryStore } from 'src/store';

export const PlatformContainer = memo(() => {
    const {
        CAR: carLevel = 0,
        HOTEL: hotelLevel = 0,
        OBJECT: objectLevel = 0,
    } = useBoundaryStore((s) => s.levelObjects);

    return (
        <div className={styles.container}>
            {/* platform */}
            <img src={platform} className={styles.platform} alt="platform" />

            {/* additional object */}
            <img
                src={getIconPlatformByLevel(hotelLevel, EPlatformItems.Hotel, 'm')}
                className={classNames(styles.object, hotelLevel < 2 && styles.disabledItem)}
                alt="additional object"
            />

            {/* hotel */}
            <img
                src={getIconPlatformByLevel(hotelLevel, EPlatformItems.Hotel, 's')}
                className={classNames(styles.hotel, hotelLevel === 0 && styles.disabledItem)}
                alt="hotel"
            />

            {/* car */}
            <img
                src={getIconPlatformByLevel(carLevel, EPlatformItems.Car)}
                className={classNames(styles.car, carLevel === 0 && styles.disabledItem)}
                alt="car"
            />

            {/* object */}
            <img
                src={getIconPlatformByLevel(objectLevel, EPlatformItems.Object)}
                className={classNames(styles.object, objectLevel === 0 && styles.disabledItem)}
                alt="object"
            />
        </div>
    );
});
