import api from 'src/api/index';
import { ObjectItemType } from 'src/api/objects';

export type UserData = {
    id: string;
    username: string;
    balance: number;
    countPoints: number;
    countClick: number;
    minerId: string;
    actionCount: number;
    level: number;
    pointsPerClick: number;
    boost: number;
    turboBoost: number;
    telegramId: number;
    photo_url: string;
    boostRefreshDate: number;
};

export type UpdateUserData = {
    countPoints?: number;
    countClick?: number;
    minerId?: string;
    turboBoost?: number;
    boost?: number;
};

export type LeadersItem = {
    id: string;
    telegramId: number;
    username: string;
    photo?: string;
    balance: number;
    userRating: number;
    rankingPlace: number;
};

type ObjectIncomeItem = {
    id: string;
    date: string;
    name: string;
    type: ObjectItemType;
    dailyIncome: number;
    objectLevel: number;
    totalIncome: number;
};

type IncomeFromActive = {
    income: number;
    type: InviteEnum;
    createdAt: string;
};

export type UserIncomeStats = {
    statistics: {
        balance: number;
        countPoints: number;
        priceObjects: number;
        incomeFromObject: number;
        totalCosts: number;
    };
    objectIncome: ObjectIncomeItem[];
    activeIncome: {
        incomeFromActive: IncomeFromActive[];
        total: number;
    }
};

export const initialUserInfo: UserData = {
    username: '',
    balance: 0,
    countPoints: 0,
    countClick: 1000,
    minerId: '',
    level: 1,
    pointsPerClick: 1,
    boost: 0,
    turboBoost: 0,
    actionCount: 0,
    boostRefreshDate: 0,
    telegramId: 1,
    id: '',
    photo_url: '',
};

export enum InviteEnum {
    'inviteToGame' = 'INVITE_TO_GAME',
    'inviteToChannel' = 'INVITE_TO_CHANNEL',
    'allocaReg' = 'ALLOCA_REG',
    'instSubscription' = 'INSTAGRAM_SUBSCRIPTION',
}

const getQuery = (refId: string) => {
    if (refId) {
        return `?referalId=${refId}`
    }
    return ''
}
export const getUserInfoApi = (refId: string) => {
    return api.get<UserData>(`users${getQuery(refId)}`);
}
export const updateUserInfoApi = (v: UpdateUserData) =>
    api.post<{ id: string }>('users', { ...v, updatedAt: new Date().toISOString() });

export const inviteApi = (userId: string) => api.post<{ id: string }>(`users/invite/${userId}`, { invite: InviteEnum.inviteToGame });
export const completeTaskApi = (invite: InviteEnum, userId: string) => api.post<{ id: string }>(`users/invite/${userId}`, { invite });

export const getLeadersApi = () => api.get<{ leaders: LeadersItem[] }>('users/leaders');
export const getIncomeStatsApi = () => api.get<UserIncomeStats>('users/stats');
