import { create, StateCreator } from 'zustand';
import { userInfoSlice, UserSlice } from 'src/store/user';
import { objectsSlice, ObjectsSlice } from 'src/store/objects';
import { minerSlice, MinerSlice } from 'src/store/miner';
import { leadersSlice, LeadersSlice } from 'src/store/leaders';
import { incomeSlice, IncomeSlice } from 'src/store/income';

interface BoundarySlice {
    isInitLoading: boolean;
    initApp: () => void;
    finishInitLoading: () => void;
}

type State = BoundarySlice & UserSlice & ObjectsSlice & MinerSlice & LeadersSlice & IncomeSlice;

const boundarySlice: StateCreator<State, [], [], BoundarySlice> = (set, get) => ({
    isInitLoading: true,
    initApp: () => {
        get().getUserInfo(true, true, () => {
            setTimeout(() => {
                get().getObjects();
                get().getLeaders();
                get().getIncomeStats();
                get().getUserAvatar();
                get().clicksCountUpdating();
            })
        });
    },
    finishInitLoading: () => {
        set({ isInitLoading: false });
    },
});

export const useBoundaryStore = create<State>()((...a) => ({
    ...boundarySlice(...a),
    ...userInfoSlice(...a),
    ...objectsSlice(...a),
    ...minerSlice(...a),
    ...leadersSlice(...a),
    ...incomeSlice(...a),
}));
