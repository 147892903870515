import React, { FC, useEffect } from 'react';
import CoinPic from '../../assets/svg/coin-header.svg';
import MinerNavbarPic from '../../assets/svg/navBar/miner.svg';
import CityPic from '../../assets/svg/navBar/city.svg';
import CityBluePic from '../../assets/images/city-blue.png';
import GoalPic from '../../assets/images/goal.png';
import { BlockWrapper } from 'src/components/block-wrapper';
import styles from './index.module.css';
import { objectsImgByType } from 'src/pages/town/constants';
import { ACTIVITY_TASKS_BY_ID } from 'src/constants';
import { nFormatter, numberWithSeparationThousands } from 'src/utils/formatters';
import { InviteEnum } from 'src/api/user';
import { useBoundaryStore } from 'src/store';
export const IncomeContainer: FC = () => {
    const data = useBoundaryStore((s) => s.stats);
    const getIncomeData = useBoundaryStore((s) => s.getIncomeStats);

    useEffect(() => {
        getIncomeData();
    }, []);

    return (
        <div className={styles.incomeWrapper}>
            <div className={styles.incomeBalance}>
                <div>Общий баланс:</div>
                <div>{nFormatter({ num: data?.statistics.balance || 0 })}</div>
                <div>
                    <img src={CoinPic} alt="O" />
                </div>
            </div>
            <div className={styles.incomeTypesBalance}>
                <div className={styles.incomeTypesBalanceItem}>
                    <img src={MinerNavbarPic} alt="miner" />
                    <div>Майнер</div>
                    <div>{numberWithSeparationThousands(data?.statistics.countPoints || 0)}</div>
                </div>
                <div className={styles.incomeTypesBalanceItem}>
                    <img src={CityPic} alt="city" />
                    <div>Город</div>
                    <div>{numberWithSeparationThousands(data?.statistics.incomeFromObject || 0)}</div>
                </div>
                <div className={styles.incomeTypesBalanceItem}>
                    <img src={GoalPic} alt="goal" />
                    <div>Активность</div>
                    <div>{numberWithSeparationThousands(data?.activeIncome?.total || 0)}</div>
                </div>
            </div>
            {data?.objectIncome.length ? (
                <BlockWrapper>
                    <div className={styles.incomeCityTitle}>
                        <div>
                            <img src={CityPic} alt="city" />
                        </div>
                        <div>Доходы города</div>
                    </div>
                    <div className={styles.incomeCitySubtitle}>
                        <div>Объект</div>
                        <div>Доход</div>
                    </div>
                    {data.objectIncome.map(({ totalIncome, name, type }) => (
                        <div key={name} className={styles.incomeCityItem}>
                            <div>
                                <div>
                                    <img src={objectsImgByType[type]} alt=" " />
                                </div>
                                <div>{name}</div>
                            </div>
                            <div>{numberWithSeparationThousands(totalIncome)}</div>
                        </div>
                    ))}
                </BlockWrapper>
            ) : (
                <BlockWrapper className={styles.emptyBlock}>
                    <img src={CityBluePic} alt="city" />
                    <div>Доходы города</div>
                    <div>У вас пока нет доходов от города</div>
                </BlockWrapper>
            )}
            <div className={styles.incomeCityDivider}></div>
            {data?.activeIncome.incomeFromActive?.length ? (
                <BlockWrapper>
                    <div className={styles.incomeCityTitle}>
                        <div>
                            <img src={GoalPic} alt=":)" />
                        </div>
                        <div>Доходы от активности</div>
                    </div>
                    <div className={styles.incomeCitySubtitle}>
                        <div>Тип активности</div>
                        <div>Доход</div>
                    </div>
                    {data.activeIncome.incomeFromActive.map(({ type: activityKey, income }) => (
                        <div key={activityKey} className={styles.incomeCityItem}>
                            <div>
                                <div>
                                    <img src={ACTIVITY_TASKS_BY_ID[activityKey].completedImg} alt=" " />
                                </div>
                                <div>{ACTIVITY_TASKS_BY_ID[activityKey].mainTitle}</div>
                            </div>
                            <div>
                                {nFormatter({ num: income, precision: 3 })}
                            </div>
                        </div>
                    ))}
                </BlockWrapper>
            ) : (
                <BlockWrapper className={styles.emptyBlock}>
                    <img src={GoalPic} alt=":)" />
                    <div>Доходы от активности</div>
                    <div>У вас пока нет доходов от активности</div>
                </BlockWrapper>
            )}
        </div>
    );
};
