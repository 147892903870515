import axios from 'axios';

const BOT_TOKEN = process.env.REACT_APP_API_KEY;

export const getProfilePhoto = async (userId?: number) => {
    if (userId) {
        const profiles = await axios.get(
            `https://api.telegram.org/bot${BOT_TOKEN}/getUserProfilePhotos?user_id=${userId}`
        );
        const file_path = await axios.get(
            `https://api.telegram.org/bot${BOT_TOKEN}/getFile?file_id=${profiles.data.result.photos[0][2].file_id}`
        );
        return `https://api.telegram.org/file/bot${BOT_TOKEN}/${file_path.data.result.file_path}`;
    }
    return ''
};
