import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import CoinPic from '../../assets/svg/coin-header.svg';
import { BlockWrapper } from 'src/components/block-wrapper';
import styles from './index.module.css';
import { imagesByObjectId, objectsImgByType, objectsNameByType } from './constants';
import { Button } from 'src/components/button';
import { AssetItemWrapper } from 'src/components/asset-item-wrapper';
import { nFormatter } from 'src/utils/formatters';
import { ObjectItemStatus, ObjectItemType } from 'src/api/objects';
import { useBoundaryStore } from 'src/store';
import { OBJECT_PRICE_MULTIPLIER } from 'src/constants';
export const TownContainer: FC = () => {
    const refCars = useRef<HTMLDivElement>(null);
    const refHotels = useRef<HTMLDivElement>(null);
    const refObjects = useRef<HTMLDivElement>(null);

    const [activeTab, setActiveTab] = useState<ObjectItemType>(ObjectItemType.car);

    const balance = useBoundaryStore((s) => s.balance);
    const isLoadingBuy = useBoundaryStore((s) => s.isLoadingBuy);
    const objectsByType = useBoundaryStore((s) => s.objectsByType);
    const objectsIncome = useBoundaryStore((s) => s.stats?.objectIncome);
    const getObjects = useBoundaryStore((s) => s.getObjects);
    const buyObject = useBoundaryStore((s) => s.handleBuyObject);
    const enableIsLoadingBuy = useBoundaryStore((s) => s.enableIsLoadingBuy);

    const handleBuy = (id: string) => {
        enableIsLoadingBuy();
        buyObject(id)
    }

    const scrollToCars = () => {
        refCars?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setActiveTab(ObjectItemType.car);
    };
    const scrollToHotels = () => {
        refHotels?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setActiveTab(ObjectItemType.hotel);
    };
    const scrollToObjects = () => {
        refObjects?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setActiveTab(ObjectItemType.object);
    };

    const ownedAmount: Record<ObjectItemType, number> = useMemo(() => {
        return {
            [ObjectItemType.car]:
                objectsByType?.CAR?.findIndex(({ status }) => status !== ObjectItemStatus.ownedStatus) || 0,
            [ObjectItemType.hotel]:
                objectsByType?.HOTEL?.findIndex(({ status }) => status !== ObjectItemStatus.ownedStatus) || 0,
            [ObjectItemType.object]:
                objectsByType?.OBJECT?.findIndex(({ status }) => status !== ObjectItemStatus.ownedStatus) || 0,
        };
    }, [isLoadingBuy]);

    const objectLevelsById: Record<string, { level: number; multiplier: number; newMultiplier: number; }> = useMemo(() => {
        return (
            objectsIncome?.reduce(
                (acc, { objectLevel: level, id }) => ({
                    ...acc,
                    [id]: { level: level + 1, multiplier: OBJECT_PRICE_MULTIPLIER ** (level + 1), newMultiplier: OBJECT_PRICE_MULTIPLIER ** (level + 2) },
                }),
                {}
            ) || {}
        );
    }, [objectsIncome?.length, isLoadingBuy]);

    useEffect(() => {
        getObjects();
    }, []);

    return (
        <div className={styles.townWrapper}>
            <div className={styles.townTabs}>
                <div onClick={scrollToCars} className={activeTab === ObjectItemType.car ? styles.activeTab : ''}>
                    <img src={objectsImgByType.CAR} alt="" />
                    {objectsNameByType.CAR}
                </div>
                <div onClick={scrollToHotels} className={activeTab === ObjectItemType.hotel ? styles.activeTab : ''}>
                    <img src={objectsImgByType.HOTEL} alt="" />
                    {objectsNameByType.HOTEL}
                </div>
                <div
                    onClick={scrollToObjects}
                    className={activeTab === ObjectItemType.object ? styles.activeTab : ''}
                >
                    <img src={objectsImgByType.OBJECT} alt="" />
                    {objectsNameByType.OBJECT}
                </div>
            </div>
            <div className={styles.townContent}>
                {Object.entries(objectsByType || {}).length ? (
                    Object.entries(objectsByType || {}).map(([key, items]) => (
                        <BlockWrapper className={styles.blockWrapper} key={key}>
                            <div
                                className={styles.top}
                                {...(key === ObjectItemType.car && { ref: refCars })}
                                {...(key === ObjectItemType.hotel && { ref: refHotels })}
                                {...(key === ObjectItemType.object && { ref: refObjects })}
                            >
                                <div>
                                    <img src={objectsImgByType[key as ObjectItemType]} alt="" />
                                    <div>{objectsNameByType[key as ObjectItemType]}</div>
                                </div>
                                <div>
                                    {ownedAmount[key as ObjectItemType]} из {items.length}
                                </div>
                            </div>
                            <div className={styles.items}>
                                {items.map(({ name, dailyIncome, price, status, id }) => {
                                    const notAvailable = balance < price && status !== ObjectItemStatus.ownedStatus;
                                    const owned = status === ObjectItemStatus.ownedStatus;
                                    const notActive = owned && balance < objectLevelsById[id]?.newMultiplier * price;
                                    return (
                                        <AssetItemWrapper
                                            key={id}
                                            img={imagesByObjectId[id]}
                                            middle={{ content: `+${nFormatter({ num: dailyIncome * (objectLevelsById[id]?.level || 1) })} / день` }}
                                            title={name}
                                            button={
                                                <Button
                                                    onClick={() => handleBuy(id)}
                                                    size={'s'}
                                                    icon={CoinPic}
                                                    type={'default'}
                                                    intent={owned ? 'success' : 'default'}
                                                    disabled={notAvailable || notActive}
                                                    className={styles.buttonBuy}
                                                >
                                                    {owned ? (
                                                        <>
                                                            Lvl {objectLevelsById[id]?.level}
                                                            <span className={styles.divider}/>
                                                            {nFormatter({ num: objectLevelsById[id]?.multiplier * price })}
                                                        </>
                                                    ) : (
                                                        `Купить за ${nFormatter({ num: price })}`
                                                    )}
                                                </Button>
                                            }
                                            className={styles.assetItemWrapper}
                                            {...(owned && {
                                                status: 'primary',
                                            })}
                                            {...(notActive && {
                                                status: 'notActive',
                                            })}
                                            {...(notAvailable && {
                                                status: 'ghost',
                                            })}
                                        />
                                    );
                                })}
                            </div>
                        </BlockWrapper>
                    ))
                ) : (
                    <div>Данных по объектам нет</div>
                )}
            </div>
        </div>
    );
};
