import React, {FC, memo} from 'react';
import classNames from 'classnames';
import BalanceTapsPic from 'src/assets/images/balance-taps.png';
import Coin from 'src/assets/svg/coin-header.svg';
import { AnimatedNumber } from 'src/components/animated-number';
import { TURBO_TIME } from 'src/constants';
import styles from './index.module.css';
import { useBoundaryStore } from 'src/store';
export const Footer: FC = memo(() => {
    const countClick = useBoundaryStore((s) => s.countClick);
    const isFrequent = useBoundaryStore((s) => s.isFrequent);
    const isTurbo = useBoundaryStore((s) => s.isTurbo);
    const isTurboView = useBoundaryStore((s) => s.isTurboView);
    const handleActivateTurbo = useBoundaryStore((s) => s.handleActivateTurbo);
    const handleStopTurbo = useBoundaryStore((s) => s.handleStopTurbo);

    const activateTurbo = () => {
        handleActivateTurbo();
        setTimeout(handleStopTurbo, TURBO_TIME);
    };
    const formatTimerValue = (v: number) => ((TURBO_TIME - v) / 1000).toFixed(2);

    return (
        <div className={styles.footer}>
            <div className={styles.footerItem}>
                <img src={BalanceTapsPic} alt="" />
                <div>{countClick}/1000</div>
            </div>
            {isFrequent && !isTurboView && !isTurbo ? (
                <div className={styles.footerItem}>
                    <div>Бонус х2</div>
                    <img src={Coin} alt="" />
                </div>
            ) : null}
            {isTurboView ? (
                <div className={styles.boostButton} onClick={activateTurbo}>
                    <div>Активировать буст</div>
                </div>
            ) : null}
            {isTurbo ? (
                <div className={classNames(styles.footerItem, styles.timer)}>
                    <AnimatedNumber value={TURBO_TIME} formatValue={formatTimerValue} duration={TURBO_TIME} />
                    <div>Турбобуст - х5</div>
                </div>
            ) : null}
        </div>
    );
});
