import { InviteEnum } from 'src/api/user';
import AllocaImg from 'src/assets/images/alloca-logo.png';
import TelegramImg from 'src/assets/images/telegram-logo.png';
import InstImg from 'src/assets/images/inst-logo.png';
import HashRushImg from 'src/assets/images/hashrush-logo.png';
import PlusImg from 'src/assets/svg/plus.svg';
import CompletedImg from 'src/assets/svg/check-green.svg';

export const DOMAIN = '/';

export enum PagesEnum {
    Main = 'main',
    City = 'city',
    Leaders = 'leaders',
    Miner = 'miner',
    Income = 'income',
    Boost = 'boost',
    Intro = 'intro',
}

export const DEFAULT_CLICKS_AMOUNT = 1000;
export const TURBO_MULTIPLIER_CLICK = 5;
export const FREQUENT_MULTIPLIER = 2;
export const FREQUENT_CPS_AMOUNT = 7;

export const TURBO_TIME = 10000;
export const UPDATING_CLICKS_TIMOUT = 3000;
export const UPDATING_USERINFO_TIMOUT = 5000;

export type ActivityTaskKey = Exclude<InviteEnum, InviteEnum.inviteToGame>;

type ActivityTasks = Record<
    InviteEnum,
    {
        mainTitle: string;
        mainSubtitle: string;
        img: string;
        completedImg: string;
        income: number;
        tasks: Array<{ title: string; subtitle: string; href: string; img: string }>;
    }
>;

export const OBJECT_PRICE_MULTIPLIER = 1.1; // доход от одного приглашенного пользователя
export const INCOME_INVITE_TO_GAME = 10000; // доход от одного приглашенного пользователя
export const INCOME_ALLOCA_REG = 10000; // сумма дохода со реги на аллоке
export const INCOME_INVITE_TO_CHANNEL = 5000; // сумма дохода от подписки на канал
export const INCOME_INSTAGRAM_SUBSCRIPTION = 10000; // сумма дохода от подписки на канал
export const ACTIVITY_TASKS_SUM_INCOME = INCOME_ALLOCA_REG + INCOME_INVITE_TO_CHANNEL + INCOME_INSTAGRAM_SUBSCRIPTION; // сумма дохода со всех заданий
export const ACTIVITY_TASKS_IDS: ActivityTaskKey[] = [InviteEnum.inviteToChannel, InviteEnum.instSubscription, InviteEnum.allocaReg];
export const ACTIVITY_TASKS_BY_ID: ActivityTasks = {
    [InviteEnum.inviteToChannel]: {
        mainTitle: '⛓️‍💥HASHRUSH CONNECTION',
        mainSubtitle: 'Подпишись на канал',
        img: HashRushImg,
        completedImg: CompletedImg,
        income: INCOME_INVITE_TO_CHANNEL,
        tasks: [
            {
                title: 'Подписка на канал «HASHRUSH»',
                subtitle:
                    'Присоединяйтесь к официальному каналу проекта, чтобы быть в курсе всех обновлений и участвовать в специальных мероприятиях!',
                href: 'https://t.me/hashrush_preview',
                img: TelegramImg,
            },
        ],
    },
    [InviteEnum.instSubscription]: {
        mainTitle: 'HashRush Instagram',
        mainSubtitle: 'Подпишись на наш инстаграм',
        img: HashRushImg,
        completedImg: CompletedImg,
        income: INCOME_INSTAGRAM_SUBSCRIPTION,
        tasks: [
            {
                title: 'Подписка на наш инстаграм «HashRush»',
                subtitle: '',
                href: 'https://www.instagram.com/hash__rush',
                img: InstImg,
            },
        ],
    },
    [InviteEnum.allocaReg]: {
        mainTitle: 'Alloca',
        mainSubtitle: 'Зарегистрируйся на платформе',
        img: AllocaImg,
        completedImg: CompletedImg,
        income: INCOME_ALLOCA_REG,
        tasks: [
            {
                title: 'Регистрация на платформе Alloca',
                subtitle: 'Платформа с актуальными новостями web-3 и аналитикой по криптопроектам.',
                href: 'https://alloca.io',
                img: AllocaImg,
            },
        ],
    },
    [InviteEnum.inviteToGame]: {
        mainTitle: 'Приглашение пользователей',
        mainSubtitle: '',
        img: PlusImg,
        completedImg: PlusImg,
        tasks: [],
        income: INCOME_INVITE_TO_GAME,
    },
};
