import { StateCreator } from 'zustand';
import { updateUserInfoApi } from 'src/api/user';
import { getMinersInfoApi, initialMinersInfo, MinersData } from 'src/api/miner';
import {UserSlice} from "src/store/user";

interface Actions {
    getMinersInfo: (minerId: string) => void;
    handleBuyMiner: (minerId: string) => void;
}

interface State {
    miner: MinersData;
}

export type MinerSlice = State & Actions;

export const minerSlice: StateCreator<MinerSlice & UserSlice, [], [], MinerSlice> = (set, get) => ({
    miner: initialMinersInfo,
    getMinersInfo: async (minerId) => {
        const { data: miner } = await getMinersInfoApi(minerId);
        set({ miner });
    },
    handleBuyMiner: async (minerId) => {
        await updateUserInfoApi({ minerId });
        get().getUserInfo(true);
    },
});
