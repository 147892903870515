import React, { FC, useEffect, useMemo, useState } from 'react';
import PlusPic from '../../assets/svg/plus.svg';
import GiftPic from '../../assets/images/gift.png';
import LeadersPic from '../../assets/svg/navBar/leaders.svg';
import {
    ACTIVITY_TASKS_BY_ID,
    ACTIVITY_TASKS_IDS,
    ACTIVITY_TASKS_SUM_INCOME,
    ActivityTaskKey,
    INCOME_INVITE_TO_GAME,
} from 'src/constants';
import { InviteEnum } from 'src/api/user';
import { LeaderBlock } from 'src/pages/leaders/LeaderBlock';
import { BonusItemBlock } from 'src/pages/leaders/LeaderBlock/BonusItemBlock';
import { InviteContent } from 'src/pages/leaders/LeaderBlock/InviteContent';
import { LeadersContent } from 'src/pages/leaders/LeaderBlock/LeadersContent';
import { TaskPage } from 'src/pages/leaders/TaskPage';
import styles from './index.module.css';
import { useBoundaryStore } from 'src/store';

export const LeadersContainer: FC = () => {
    const userId = useBoundaryStore((s) => s.id);
    const leaderIds = useBoundaryStore((s) => s.leaderIds);
    const leadersById = useBoundaryStore((s) => s.leadersById);
    const completedTasks = useBoundaryStore((s) => s.stats?.activeIncome.incomeFromActive);
    const isLoadingCompleteTask = useBoundaryStore((s) => s.isLoadingCompleteTask);
    const isLoadedAvatars = useBoundaryStore((s) => s.isLoadedAvatars);
    const isLoadingLeaders = useBoundaryStore((s) => s.isLoadingLeaders);
    const photoById = useBoundaryStore((s) => s.photoById);
    const getIncomeData = useBoundaryStore((s) => s.getIncomeStats);
    const getLeadersData = useBoundaryStore((s) => s.getLeaders);
    const switchOnIsLoadingCompleteTask = useBoundaryStore((s) => s.switchOnIsLoadingCompleteTask);
    const completeTaskStore = useBoundaryStore((s) => s.completeTask);
    const setIsLoadingLeaders = useBoundaryStore((s) => s.setIsLoadingLeaders);

    const [selectedTask, setSelectedTask] = useState<ActivityTaskKey>();
    const completedTaskTypes = useMemo(() => completedTasks?.map(({ type }) => type), [completedTasks?.length]);
    const completeTask = (id: InviteEnum) => {
        switchOnIsLoadingCompleteTask();
        completeTaskStore(id, closeTaskPage);
    };

    const closeTaskPage = () => setSelectedTask(undefined);

    const bonusesContent = useMemo(
        () => (
            <div>
                {ACTIVITY_TASKS_IDS.map((taskId) => (
                    <BonusItemBlock
                        key={taskId}
                        title={ACTIVITY_TASKS_BY_ID[taskId].mainTitle}
                        subtitle={ACTIVITY_TASKS_BY_ID[taskId].mainSubtitle}
                        img={ACTIVITY_TASKS_BY_ID[taskId].img}
                        onSelectTask={() => setSelectedTask(taskId)}
                        isCompleted={completedTaskTypes?.includes(taskId)}
                    />
                ))}
            </div>
        ),
        [completedTaskTypes?.length]
    );

    const leaders = useMemo(
        () => leaderIds.map((id) => leadersById[id]),
        [leaderIds.length, leadersById, isLoadedAvatars, isLoadingLeaders]
    );

    useEffect(() => {
        setIsLoadingLeaders();
        getIncomeData();
        getLeadersData();
    }, []);

    return (
        <div className={styles.leadersWrapper}>
            {selectedTask ? (
                <TaskPage
                    handleCompleteTask={completeTask}
                    handleBack={closeTaskPage}
                    isCompleted={completedTaskTypes?.includes(selectedTask)}
                    selectedTask={selectedTask}
                    isLoadingCompleteTask={isLoadingCompleteTask}
                />
            ) : (
                <>
                    <LeaderBlock
                        title={'Пригласи друга'}
                        pic={PlusPic}
                        points={INCOME_INVITE_TO_GAME}
                        content={<InviteContent userId={userId} />}
                    />
                    <LeaderBlock
                        title={'Бонусы от партнеров'}
                        pic={GiftPic}
                        points={ACTIVITY_TASKS_SUM_INCOME}
                        content={bonusesContent}
                    />
                    <LeaderBlock
                        title={'Список лидеров'}
                        pic={LeadersPic}
                        content={<LeadersContent leaders={leaders} photoById={photoById} userId={userId} />}
                        className={styles.blockWrapper}
                    />
                </>
            )}
        </div>
    );
};
