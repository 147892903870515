import React, { FC } from 'react';
import styles from 'src/pages/leaders/index.module.css';
import { LeaderItem } from 'src/pages/leaders/LeaderBlock/LeadersContent/LeaderItem';
import { LeadersItem } from 'src/api/user';

type Props = {
    leaders?: LeadersItem[];
    photoById: Record<string, string>;
    userId: string;
};
export const LeadersContent: FC<Props> = ({ leaders, userId, photoById }) =>
    leaders?.length ? (
        <div>
            <div className={styles.tableHeader}>
                <div>Место</div>
                <div>Участник</div>
                <div>Очки</div>
            </div>
            <div style={{ position: 'relative' }}>
                {leaders.map((player) => (
                    <LeaderItem
                        key={player.id}
                        player={player}
                        avatar={photoById[player.id]}
                        isCurrentPlayer={player.id === userId}
                    />
                ))}
            </div>
        </div>
    ) : (
        <div style={{ paddingBottom: '8px' }}>Данных по игрокам нет</div>
    );
