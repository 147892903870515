import CarPic from '../../assets/svg/car.svg';
import ObjectPic from '../../assets/svg/object.svg';
import CityPic from '../../assets/svg/navBar/city.svg';
import Car1 from '../../assets/images/object-cards/car-1.png';
import Car2 from '../../assets/images/object-cards/car-2.png';
import Car3 from '../../assets/images/object-cards/car-3.png';
import Car4 from '../../assets/images/object-cards/car-4.png';
import Car5 from '../../assets/images/object-cards/car-5.png';
import Hotel1 from '../../assets/images/object-cards/hotel-1.png';
import Hotel2 from '../../assets/images/object-cards/hotel-2.png';
import Hotel3 from '../../assets/images/object-cards/hotel-3.png';
import Hotel4 from '../../assets/images/object-cards/hotel-4.png';
import Hotel5 from '../../assets/images/object-cards/hotel-5.png';
import Object1 from '../../assets/images/object-cards/object-1.png';
import Object2 from '../../assets/images/object-cards/object-2.png';
import Object3 from '../../assets/images/object-cards/object-3.png';
import Object4 from '../../assets/images/object-cards/object-4.png';
import Object5 from '../../assets/images/object-cards/object-5.png';
import {ObjectItem, ObjectItemStatus, ObjectItemType} from "src/api/objects";


export const objectsNameByType: Record<ObjectItemType, string> = {
    [ObjectItemType.car]: 'Машины',
    [ObjectItemType.hotel]: 'Отели',
    [ObjectItemType.object]: 'Объекты',
};
export const objectsImgByType: Record<ObjectItemType, string> = {
    [ObjectItemType.car]: CarPic,
    [ObjectItemType.hotel]: CityPic,
    [ObjectItemType.object]: ObjectPic,
};

export const imagesByObjectId: Record<string, string> =  {
    '1': Car1,
    '2': Car2,
    '3': Car3,
    '4': Car4,
    '5': Car5,
    '6': Hotel1,
    '7': Hotel2,
    '8': Hotel3,
    '9': Hotel4,
    '10': Hotel5,
    '11': Object1,
    '12': Object2,
    '13': Object3,
    '14': Object4,
    '15': Object5,
}
