import React, { FC, useState } from 'react';
import { LeaderBlock } from 'src/pages/leaders/LeaderBlock';
import { ACTIVITY_TASKS_BY_ID } from 'src/constants';
import { BonusItemBlock } from 'src/pages/leaders/LeaderBlock/BonusItemBlock';
import { Button } from 'src/components/button';
import { InviteEnum } from 'src/api/user';
import BackPic from 'src/assets/svg/chevron-left.svg';
import styles from './index.module.css';

type Props = {
    selectedTask: InviteEnum;
    handleBack: () => void;
    isCompleted?: boolean;
    isLoadingCompleteTask: boolean;
    handleCompleteTask: (id: InviteEnum) => void;
};
export const TaskPage: FC<Props> = ({
    selectedTask,
    isCompleted = false,
    isLoadingCompleteTask,
    handleBack,
    handleCompleteTask,
}) => {
    const [localIsCompleted, setLocalIsCompleted] = useState(isCompleted);

    const onSelectTask = (href: string) => {
        window.open(href, '_blank')?.focus();
        setTimeout(() => setLocalIsCompleted(true), selectedTask === InviteEnum.instSubscription ? 10000 : 2000);
    };

    return (
        <>
            <div className={styles.backButton} onClick={handleBack}>
                <img src={BackPic} alt="back" />
                Назад
            </div>
            <LeaderBlock
                title={ACTIVITY_TASKS_BY_ID[selectedTask].mainTitle}
                pic={ACTIVITY_TASKS_BY_ID[selectedTask].img}
                points={ACTIVITY_TASKS_BY_ID[selectedTask].income}
                content={
                    <div>
                        {ACTIVITY_TASKS_BY_ID[selectedTask].tasks.map(({ href, title, subtitle, img }) => (
                            <BonusItemBlock
                                key={href}
                                img={img}
                                title={title}
                                subtitle={subtitle}
                                isCompleted={localIsCompleted}
                                onSelectTask={() => onSelectTask(href)}
                            />
                        ))}
                    </div>
                }
            />
            <Button
                disabled={isCompleted || !localIsCompleted}
                className={styles.button}
                onClick={() => handleCompleteTask(selectedTask)}
                isLoading={isLoadingCompleteTask}
            >
                Получить бонус
            </Button>
        </>
    );
};
