import React, { memo } from 'react';
import styles from 'src/pages/start/index.module.css';
import StartBg from 'src/assets/images/start-bg.png';
import LogoPic from 'src/assets/images/logo-game.png';
import classNames from 'classnames';

type Props = {
    isDesktop?: boolean;
};
export const StartWrapper = memo(({ isDesktop }: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.body}>
                <img className={styles.startBg} src={StartBg} alt="" />
                <div className={styles.gradient}></div>
                <img className={styles.logo} src={LogoPic} alt="" />
                <div className={classNames(styles.text, isDesktop && styles.textDesktop)}>
                    {isDesktop
                        ? 'Игра работает только на мобильных устройствах. Пожалуйста, продолжите игру там'
                        : 'Загрузка'}
                </div>
            </div>
        </div>
    );
});
